import * as React from "react"
import Layout from "../components/layout"
import TitleRotating from "../components/titleRotating"
import FeatureArticle from "../components/featureArticle"
import NewsFeed from "../components/newsFeed"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const JournalPage = ({ data }) => {
  var journal = data.journal
  var articles = data.articles.nodes
  return (
    <Layout>
      <TitleRotating title="Stories about" words={['Good people','Good news','Good work', 'Good results', 'Good people']} />
      <FeatureArticle post={journal.featuredPost} />
      <NewsFeed articles={articles} />
    </Layout>
  )
}

export default JournalPage

export const Head = () => <Seo title="Journal" />

export const query = graphql`
  query JournalQuery {
    journal: datoCmsJournal {
      featuredPost {
        title
        slug
        featureImage {
          gatsbyImageData
        }
      }
    }
    articles: allDatoCmsBlogPost {
      nodes {
        title
        slug
        featureImage {
          gatsbyImageData
        }
        category
      }
    }
  }
`
