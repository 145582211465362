import * as React from "react"
import { Link } from "gatsby"

const Button = ({ text, link, className='' }) => {
  return (
    <>
    {( link ?
      <Link className={"button link medium "+className} to={link}>
        {text} <span className="arrow ml20" />
      </Link>
      :
      <p className={'button link medium '+className}>
      {text} <span className="arrow ml20" />
      </p>
    )}
    </>
  )
}

export default Button
