import * as React from "react"
import Button from "../components/button"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Post = ({ journal }) => {
  return (
    <Link className='link image-hover fast journal-post' to={"/post/" + journal.slug}>
      <div className='h-100p flex flex-wrap'>
        <div className='w-100'>
          <div className='ratio-1-1 bg-grey pos-rel overflow-hidden m-ratio-2-1'>
            <GatsbyImage className='bg-image' image={journal.featureImage?.gatsbyImageData} alt='Feature Image' />
          </div>
          <p className='op-50 m0 mt20 m-h4 mb20 m-mb10 medium'>{journal.category}</p>
          <p className='large m0 mb40 m-mb20'>{journal.title}</p>
        </div>
        <div className='w-100 mta'>
          <Button text='Read More' />
        </div>
      </div>
    </Link>
  )
}

export default Post
