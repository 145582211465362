import * as React from "react"
import Button from "../components/button"
import { GatsbyImage } from "gatsby-plugin-image"
import Post from "../components/post"
import { Link } from "gatsby"

const NewsFeed = ({ articles }) => {
  return (
    <div className="news--feed p40">
      <div className="flex mb80 mt80">
        <p className="h4 m0">News + Events</p>
        <p className="op-50 m0 mla medium mta m-mba">News Category</p>
      </div>
      <FirstArticle article={articles[0]} />
      <div className="grid mt80 mb40 grid-gap">
        {articles.map((journal, index) => {
          return (
            <Post key={index} journal={journal} />
          )
        })}
      </div>
    </div>
  )
}

export default NewsFeed

const FirstArticle = ({ article }) => {
  return (
    <Link className='link image-hover fast journal-post' to={"/post/" + article.slug}>
      <div className="first-article flex m-wrap">
        <div className="w-50 m-100">
          <div className="ratio-1-1 bg-grey pos-rel m-ratio-2-1 overflow-hidden">
            <GatsbyImage
              className="bg-image"
              image={article.featureImage?.gatsbyImageData}
              alt="Feature Image"
            />
          </div>
        </div>
        <div className="w-50 m-100">
          <div className="pl40 m-p0 m-mt20">
            <p className="m0 op-50 mb40 m-mb20">{article.category}</p>
            <p className="h3 m-h4 m0 max-500 mb20">{article.title}</p>
            <Button text="Read More" />
          </div>
        </div>
      </div>
    </Link>
  )
}
