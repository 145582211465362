import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const FeaturedArticle = ({ post }) => {
  return (
    <Link to={`/post/${[post.slug]}`} className="link">
      <div className='feature--image'>
        <div className='mh-60 m-mh90 flex bg-grey pos-rel overflow-hidden image-zoom' data-sal data-sal-offset='500'>
          <div className='pos-rel z-2 light mta mba p40 max-900'>
            <p className='h4 m0'>Featured Article</p>
            <p className='h1 m0 mb80'>{post.title}</p>
            <p className='button medium'>
              Read More <span className='arrow ml20' />
            </p>
          </div>
          <GatsbyImage className='bg-image' image={post.featureImage?.gatsbyImageData} alt='Feature Image' />
        </div>
      </div>
    </Link>
  )
}
export default FeaturedArticle
