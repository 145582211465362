import React, { useEffect } from "react"
import { gsap } from "gsap"
import Spacer from "../components/spacer"

const TitleRotating = ({ title, words }) => {
  useEffect(() => {

    document.querySelector('.rotating-text').style.height = document.querySelector('.rotating-word').offsetHeight+'px';

    gsap.fromTo(
      ".rotating-wrapper .rotating-inner",
      { y: "110%", skewY: "3deg" },
      { y: 0, skewY: 0, duration: 1, delay: 0.25, ease: "Power3.easeOut" }
    )
    var tl = gsap.timeline({ paused: true, repeat:-1, delay:2});
    tl.to(".rotating-text .inner", {y: '-20%', delay:0.5, duration: 1});
    tl.to(".rotating-text .inner", {y: '-40%', delay:0.5, duration: 1});
    tl.to(".rotating-text .inner", {y: '-60%', delay:0.5, duration: 1});
    tl.to(".rotating-text .inner", {y: '-80%', delay:0.5, duration: 1});
    tl.play();
  })
  return (
  <div className="title--main p40">
    <Spacer />
    <div className="m0 h1 rotating-wrapper overflow-hidden">
      <div className='flex m-wrap rotating-inner'>
        <p className='m0 mr15 m-100'>{title}</p>
        <div className='rotating-text m-100'>
          <div className='inner'>
            {words.map((word, index) => {
              return (
                <p className='rotating-word m0'>{word}</p>
              )
            })}
          </div>
        </div>
      </div>
    </div>
    <Spacer />
  </div>
  )
}

export default TitleRotating
